import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
// import Grid from "@mui/material/Grid";
// import CustomEventEmitter from "@ui/Utils/CustomEventEmitter";
// import { downloadFile, isAdmin } from "@ui/Utils/helper";
// import { format } from "date-fns";
import React, { useEffect, useRef, useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { useNavigate, useParams, useLocation } from "react-router-dom";
// import { createGlobalStyle } from "styled-components";
import CustomEventEmitter from "@ui/Utils/CustomEventEmitter";
import {
  createRecord,
  getByCode,
  updateRecord,
} from "@ui/ComponentUtils/blueprintAPIs";

import _ from "lodash";
// import DetailContent from "./components/DetailContent";
import DetailHeader from "./components/DetailHeader";
import commonEnums from "@ui/Utils/commonEnums";

const validationSchema = yup.object().shape({
  name: yup.string().required("Required *"),
  logo: yup.object().shape({
    location: yup.string().required("Location is required"),
  }),
});

const entitySchema = {};

const SoftAlleyDetail = () => {
  const formMethods = useForm({
    defaultValues: entitySchema,
    mode: "all",
    resolver: yupResolver(validationSchema),
  });
  const navigate = useNavigate();

  const params = useParams();

  const [isEditable, setIsEditable] = useState(false);

  useEffect(() => {
    (async () => {
      const code = params?.code;

      try {
        if (code !== "create") {
          const res = await getByCode(
            commonEnums.models["data-engine"].softAllies,
            code,
            `populate=${JSON.stringify({
              path: "client",
              select: "_id name premiumClient",
              db: "iam",
              collection: "clients",
            })}`
          );

          formMethods.reset(res);
          setIsEditable(false);
        } else {
          setIsEditable(true);
        }
      } catch (err) {
        navigate(commonEnums.models["data-engine"].softAllies);
        console.log(err);
      }
    })();
  }, [params?.id]);

  const onSubmit = async (values) => {
    try {
      let res = {},
        action = "Created";

      if (!values.code) {
        res = await createRecord(
          commonEnums.models["data-engine"].softAllies,
          values
        );
        CustomEventEmitter.emit("alert_success", "Successfully " + action);
        return navigate(`/${commonEnums.models["data-engine"].softAllies}`);
      } else {
        res = await updateRecord(commonEnums.models["data-engine"].softAllies, {
          ...values,
          populate: {
            path: "client",
            select: "_id name premiumClient",
            db: "iam",
            collection: "clients",
          },
        });

        action = "Updated";
      }

      formMethods.reset(res);
      setIsEditable(false);
      CustomEventEmitter.emit("alert_success", "Successfully " + action);
    } catch (err) {
      console.log(err?.response?.data?.message);
      let message = err?.response?.data?.message || "Something went wrong!";

      CustomEventEmitter.emit("alert_error", message);
    }
  };

  return (
    <FormProvider {...formMethods}>
      <DetailHeader
        isEditable={isEditable}
        setIsEditable={setIsEditable}
        onSubmit={onSubmit}
      />

      {/* <DetailContent isEditable={isEditable} /> */}
    </FormProvider>
  );
};

export default SoftAlleyDetail;
