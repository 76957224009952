import React from "react";
import GeneralListPage from "@ui/ComponentUtils/GeneralListPage";
import { formatDateToRead, joinWithSeparator } from "@ui/Utils/helper";
import { globalDateTimeFormat } from "@ui/ReactHookFormControls/index";
import commonEnums from "@ui/Utils/commonEnums";

const columns = [
  {
    title: "Name",
    field: "name",
  },
  {
    title: "Created By",
    // field: "createdBy.firstName",
    sorting: false, // due to invalid createdBy.firstName old data
    render: (row) => (
      <div>
        <div style={{ fontWeight: "bold" }}>
          {joinWithSeparator(
            [row.createdBy?.firstName, row.createdBy?.lastName],
            " "
          )}
        </div>
        <div style={{ fontSize: "10px", fontStyle: "italic" }}>
          {row.dateCreated
            ? formatDateToRead(row.dateCreated, globalDateTimeFormat.read)
            : ""}
        </div>
      </div>
    ),
  },
  {
    title: "Modified By",
    sorting: false, // due to invalid createdBy.firstName old data
    render: (row) => (
      <div>
        <div style={{ fontWeight: "bold" }}>
          {joinWithSeparator(
            [row.modifiedBy?.firstName, row.modifiedBy?.lastName],
            " "
          )}
        </div>
        <div style={{ fontSize: "10px", fontStyle: "italic" }}>
          {row.dateModified
            ? formatDateToRead(row.dateModified, globalDateTimeFormat.read)
            : ""}
        </div>
      </div>
    ),
  },
  {
    title: "Status",
    field: "status",
    sorting: false, // due to invalid createdBy.firstName old data
    render: (row) => (
      <div style={{ color: row.status ? "green" : "red", fontWeight: "bold" }}>
        {row.status ? "Active" : "In Active"}
      </div>
    ),
  },
];

const SoftAlliesList = () => {
  return (
    <GeneralListPage
      columns={columns}
      model={commonEnums.models["data-engine"].softAllies}
      headerTitle="Alliessss"
      hideAction={{ delete: true, selection: true }}
      userFields={["$createdBy.firstName", "$createdBy.lastName"]}
      searchFields={["appModule.key"]}
      dateFields={["startDate", "endDate", "dateCreated"]}
    />
  );
};

export default SoftAlliesList;
