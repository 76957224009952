import Emitter from "@ui/Utils/CustomEventEmitter";
import HTTP from "@ui/Utils/http";
import commonConfig from "config/commonConfig";

const baseApiUrl = commonConfig.baseApiUrl;

export const subscribeApp = async (body) => {
  try {
    const res = await HTTP.post(
      baseApiUrl + "c/iam/clients/subscribeApp",
      body
    );

    return res?.data?.data;
  } catch (err) {
    console.log(err);
    throw err;
  }
};

export const unsubscribeApp = async ({ subscriptionId, endDate, clientId }) => {
  try {
    const res = await HTTP.post(baseApiUrl + "c/iam/clients/unsubscribeApp", {
      subscriptionId,
      clientId,
      endDate,
    });

    return res?.data?.data;
  } catch (err) {
    console.log(err);
    throw err;
  }
};

export const confirmPassword = () => {
  const password = prompt("Enter your password:");

  if (password === "101") {
    return true;
  }

  if (password !== null) Emitter.emit("alert_error", "Wrong password");
  return false;
};
