import CustomEventEmitter from "@ui/Utils/CustomEventEmitter";
import React, { useEffect, useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { useNavigate, useParams } from "react-router-dom";
import { useAuth } from "@ui/components/AuthProvider";

import {
  createRecord,
  getSingleRecordWithHook,
  paginate,
  updateRecord,
} from "@ui/ComponentUtils/blueprintAPIs";

import _ from "lodash";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import GeneralSection from "@ui/ComponentUtils/GeneralSection";
import Header from "@ui/ComponentUtils/Header";
import enums from "helpers/enums";
import { handleGlobalUserControl, toTitleCase } from "@ui/Utils/helper";
import DetailContent from "./components/DetailContent";

// const GlobalStyle = createGlobalStyle`
//    body {
//     background: linear-gradient(
//     180deg,
//     rgba(255, 88, 88, 0.1) 0%,
//     rgba(123, 166, 229, 0.31) 69.79%
//   );
//     font-family: 'Advent Pro', sans-serif;
//     min-height: 100vh;
//     padding-inline: 2vh;
//     overflow: auto;
//   }

// `;

const validationSchema = yup.object().shape({
  phone: yup.string().required("Required"),
  // .matches(/^[0-9]{10}$/, "Invalid Phone"), // Adjust the regex for your phone format
  // email: yup.string().email("Invalid Email"),
  firstName: yup.string().required("Required"),
  lastName: yup.string().required("Required"),
  client: yup.object().nullable().required("Required"),
  role: yup.string().required("Required"),
});

const entitySchema = {
  firstName: "",
  lastName: "",
  phone: "",
  email: "",
};

const UserDetail = () => {
  const formMethods = useForm({
    defaultValues: entitySchema,
    mode: "all",
    resolver: yupResolver(validationSchema),
  });
  const navigate = useNavigate();

  const params = useParams();

  const [clients, setClients] = useState([]);
  const [isEditable, setIsEditable] = useState(false);

  const values = formMethods.watch();

  useEffect(() => {
    (async () => {
      const code = params?.code;

      try {
        if (code !== "create") {
          const res = await getSingleRecordWithHook(enums.models.users, {
            code,
            filterColumns: {
              activeSessions: 1,
              firstName: 1,
              lastName: 1,
              phone: 1,
              email: 1,
              role: 1,
              _id: 1,
              code: 1,
              disabled: 1,
            },
            populate: [{ path: "client", select: "name" }],
          });
          formMethods.reset(res);
          setIsEditable(false);
        } else {
          const res = await paginate(enums.models.clients);
          setClients(res || []);
          setIsEditable(true);
        }
      } catch (err) {
        navigate(enums.models.users);
        console.log(err);
      }
    })();
  }, [params?.code]);

  const onSubmit = async (values) => {
    try {
      if (values.password?.toString()?.trim()) {
        values.changePasswordFlag = true;
      }

      values.firstName = toTitleCase(values.firstName);
      values.lastName = toTitleCase(values.lastName);

      let action = "Created",
        res = {};
      if (!values.code) {
        res = await createRecord(enums.models.users, values);
        navigate(`/${enums.models.users}/${res?.code}`);
      } else {
        res = await updateRecord(enums.models.users, values);
        action = "Updated";
      }
      // formMethods.reset(res);
      // setIsEditable(false);
      CustomEventEmitter.emit("alert_success", "Successfully " + action);
      window.location.reload();
    } catch (err) {
      CustomEventEmitter.emit(
        "alert_error",
        err?.response?.data?.message || "Something went wrong"
      );
    }
  };

  const handleCancel = () => {
    if (window.confirm("Are you sure to cancel? Changes will be discarded.")) {
      if (params?.id === "create") {
        navigate("/" + enums.models.users);
      } else {
        formMethods.reset();
        setIsEditable(false);
      }
    }
  };

  const entityFields = [
    {
      name: "firstName",
      label: "First Name",
      fieldType: "textField",
    },
    {
      name: "lastName",
      label: "Last Name",
      fieldType: "textField",
    },
    {
      name: "client",
      label: "Client",
      fieldType: "dropdown",
      options: clients || [],
      getOptionLabel: (option) => option?.name || "",
      render: "client.name",
      readOnly: values.code,
    },
    {
      name: "password",
      label: "Password",
      fieldType: "textField",
      // type: "password",
      hash: true,
    },
    {
      name: "phone",
      label: "Phone",
      fieldType: "textField",
    },
    {
      name: "email",
      label: "Email",
      fieldType: "textField",
    },
    {
      name: "role",
      label: "Role",
      fieldType: enums.fieldTypes.dropdown,
      options: [enums.roles.superAdmin, enums.roles.admin],
    },
  ];

  const links = [
    {
      name: "Users",
      url: "#/" + enums.models.users,
      isDisplayLink: true,
    },
    {
      name: values.firstName + " " + values.lastName,
      url: "",
      isDisplayLink: false,
    },
  ];

  const actions = [
    {
      label: "Cancel",
      iconName: "cancel",
      hidden: !isEditable,
      onClick: handleCancel,
    },
    {
      label: "Save",
      iconName: "save",
      hidden: !isEditable,
      type: "submit",
      onClick: formMethods.handleSubmit(onSubmit),
    },
    {
      label: "Edit",
      iconName: "edit",
      hidden: isEditable,
      onClick: () => {
        setIsEditable(true);
      },
    },
    {
      label: "Signout All Apps",
      hidden: isEditable,
      disabled: !values.activeSessions?.length,
      iconName: "signout",
      tooltip: "Signout " + values.firstName,
      onClick: async () => {
        await handleGlobalUserControl(
          {
            users: [
              {
                _id: values._id,
                firstName: values.firstName,
                lastName: values.lastName,
              },
            ],
          },
          ["iam"]
        );

        window.location.reload();
      },
      color: "primary",
    },
    {
      label: values.disabled ? "Approve User" : "Disable User",
      hidden: isEditable,
      iconName: values.disabled ? "disable" : "update",
      tooltip: values.disabled ? "Approve User" : "Disable User",
      onClick: async () => {
        await handleGlobalUserControl(
          {
            users: [
              {
                _id: values._id,
                firstName: values.firstName,
                lastName: values.lastName,
              },
            ],
            iamControl: { disabled: !values.disabled },
          },
          ["iam"]
        );

        window.location.reload();
      },
      color: "primary",
    },
  ];

  return (
    <FormProvider {...formMethods}>
      <Header links={links} pageTitle={"Users"} actions={actions} />

      <GeneralSection
        fields={entityFields}
        title="Information"
        isEditable={isEditable}
        values={values}
      />

      <DetailContent values={values} isEditable={isEditable} />
    </FormProvider>
  );
};

export default UserDetail;
