import React, { useState, useEffect } from "react";
import MyDateTimePicker from "@ui/MuiComponents/DatePicker/MyDateTimePicker";
import {
  confirmPassword,
  subscribeApp,
  unsubscribeApp,
} from "views/Clients/api";
import { Grid } from "@material-ui/core/index";
import Emitter from "@ui/Utils/CustomEventEmitter";
import { updateRecordWithHook } from "@ui/ComponentUtils/blueprintAPIs";
import enums from "helpers/enums";
import Label from "@ui/components/Label";
import { formatDateToRead } from "@ui/Utils/helper";
import { globalDateTimeFormat } from "@ui/ReactHookFormControls/index";

const Subscription = ({ appModule, values, setValue }) => {
  const [openPicker, setOpenPicker] = useState(false);
  const [openStartPicker, setOpenStartPicker] = useState(false);
  const existingApp = values.appModules?.find(
    (module) => module?._id === appModule?._id
  );

  const [startDate, setStartDate] = useState(
    existingApp?.subscription?.startDate
      ? new Date(existingApp.subscription.startDate)
      : null
  );
  const [endDate, setEndDate] = useState(
    existingApp?.subscription?.endDate
      ? new Date(existingApp.subscription.endDate)
      : null
  );
  useEffect(() => {
    setEndDate(
      existingApp?.subscription?.endDate
        ? new Date(existingApp.subscription.endDate)
        : null
    );
  }, [existingApp?.endDate]);

  useEffect(() => {
    setStartDate(
      existingApp?.subscription?.startDate
        ? new Date(existingApp.subscription.startDate)
        : null
    );
  }, [existingApp?.startDate]);

  const handleUnsubscribe = async () => {
    try {
      if (!existingApp?.subscription?._id) {
        return Emitter.emit("alert_error", "Old Data.. Contact Abhi");
      }

      if (confirmPassword()) {
        const clientData = await unsubscribeApp({
          subscriptionId: existingApp?.subscription._id,
          clientId: values._id,
        });

        setValue("appModules", clientData.appModules);
        setEndDate(null);
        setStartDate(null);

        Emitter.emit("alert_success", "Unsubscribed Successfully...");
      }
    } catch (err) {
      Emitter.emit(
        "alert_error",
        err?.response?.data?.message
          ? err?.response?.data?.message + ", Reload!"
          : "Something went wrong!"
      );
    }
  };

  const handleSubscribe = async (dateValue) => {
    try {
      if (confirmPassword()) {
        const clientData = await subscribeApp({
          appModule,
          startDate: new Date(),
          endDate: dateValue,
          clientId: values._id,
        });

        setValue("appModules", clientData.appModules);
        const match = clientData.appModules.find(
          (app) => appModule._id === app._id
        );
        setEndDate(match.subscription.endDate);
        setStartDate(match.subscription.startDate);
        setOpenPicker(false);

        Emitter.emit("alert_success", "Subscribed Successfully...");
      }
    } catch (err) {
      console.log(err);
      Emitter.emit(
        "alert_error",
        err?.response?.data?.message || "Something went wrong!"
      );
    }
  };

  const updateSubscriptionDate = async (dateValue, { start } = {}) => {
    try {
      const body = {
        code: existingApp?.subscription.code,
      };
      if (start) body.startDate = dateValue;
      else body.endDate = dateValue;

      if (confirmPassword()) {
        const res = await updateRecordWithHook(
          enums.models.subscriptions,
          body
        );

        setValue(
          "appModules",
          values.appModules.map((app) => {
            if (app.subscription._id === res._id) {
              app.subscription = res;
            }
            return app;
          })
        );
        if (start) setStartDate(dateValue);
        else setEndDate(dateValue);

        Emitter.emit(
          "alert_success",
          "Updated Subscription End Date Successfully..."
        );
      }
    } catch (err) {
      Emitter.emit(
        "alert_error",
        err?.response?.data?.success === false
          ? "Active Subscription not found. Reload!"
          : "Something went wrong!"
      );
    }
  };

  return (
    <Grid container spacing={3}>
      {/* Date Picker for subscription */}
      <Grid item lg={2}>
        {/* Subscribe / Unsubscribe Button */}
        <button
          onClick={() => {
            if (existingApp) {
              handleUnsubscribe();
            } else {
              setOpenPicker(true);
            }
          }}
          className={`subscribe-btn ${existingApp ? "unsubscribe-btn" : ""}`}
        >
          {existingApp ? "Unsubscribe" : "Subscribe"}
        </button>
      </Grid>

      {existingApp ? (
        <>
          <Grid item lg={2}>
            <Label
              label={"Subscribed by"}
              value={
                existingApp?.subscription.createdBy.firstName +
                " " +
                existingApp?.subscription.createdBy.lastName +
                ", " +
                (existingApp?.subscription.dateCreated
                  ? formatDateToRead(
                      existingApp?.subscription.dateCreated,
                      globalDateTimeFormat.read
                    )
                  : "")
              }
            />
          </Grid>
          <Grid item lg={3}>
            {existingApp ? (
              <MyDateTimePicker
                value={startDate}
                open={openStartPicker}
                onClick={() => setOpenStartPicker(true)}
                clearable={false}
                onClose={() => setOpenStartPicker(false)}
                onChange={async (dateValue) => {
                  if (dateValue) {
                    await updateSubscriptionDate(dateValue, { start: true });
                  }
                }}
                label="Start"
              />
            ) : null}
          </Grid>
          <Grid item lg={3}>
            {existingApp ? (
              <MyDateTimePicker
                value={endDate}
                open={openPicker}
                onClick={() => setOpenPicker(true)}
                clearable={false}
                onClose={() => setOpenPicker(false)}
                onChange={async (dateValue) => {
                  if (dateValue) {
                    await updateSubscriptionDate(dateValue);
                  }
                }}
                // disablePast
                label="End"
              />
            ) : null}
          </Grid>

          {existingApp?.subscription?.modifiedBy?._id ? (
            <>
              <Grid item lg={2}>
                <Label
                  label={"Modified by"}
                  value={
                    existingApp?.subscription.modifiedBy.firstName +
                    " " +
                    existingApp?.subscription.modifiedBy.lastName +
                    (existingApp?.subscription.dateModified
                      ? ", " +
                        formatDateToRead(
                          existingApp?.subscription.dateModified,
                          globalDateTimeFormat.read
                        )
                      : "")
                  }
                />
              </Grid>
            </>
          ) : null}

          <Grid item lg={12}>
            {endDate && new Date(endDate).getTime() < new Date() ? (
              <h2 style={{ color: "red" }}>EndDate Exceeeeeeeeded</h2>
            ) : (
              ""
            )}
          </Grid>
        </>
      ) : null}

      {/* DatePicker Modal on Subscribe */}
      {!existingApp && openPicker && (
        <div className="modal-overlay">
          <div className="modal-content">
            <MyDateTimePicker
              value={endDate}
              open={true}
              clearable={false}
              onClose={() => setOpenPicker(false)}
              onChange={async (dateValue) => {
                if (dateValue) {
                  await handleSubscribe(dateValue);
                }
              }}
              disablePast
              label="Select Expiry Date & Time"
            />
          </div>
        </div>
      )}

      <style jsx>{`
        .modal-overlay {
          position: fixed;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          background: rgba(0, 0, 0, 0.7);
          display: flex;
          align-items: center;
          justify-content: center;
        }
        .modal-content {
          background: white;
          padding: 20px;
          border-radius: 8px;
        }
        .subscribe-btn {
          padding: 10px 20px;
          font-size: 16px;
          cursor: pointer;
          border: none;
          border-radius: 5px;
          background: #007bff;
          color: white;
        }
        .unsubscribe-btn {
          background: red;
        }
      `}</style>
    </Grid>
  );
};

export default Subscription;
