import GeneralSection from "@ui/ComponentUtils/GeneralSection";
import Header from "@ui/ComponentUtils/Header";
import enums from "helpers/enums";
import React, { useEffect, useState } from "react";
import { useFormContext } from "react-hook-form";
import { useNavigate, useParams } from "react-router-dom";
import { formatDateToRead } from "@ui/Utils/helper";
import { globalDateTimeFormat } from "@ui/ReactHookFormControls/index";

const DetailHeader = ({ isEditable, onSubmit, setIsEditable }) => {
  const formMethods = useFormContext();
  const values = formMethods.getValues();
  const params = useParams();
  const navigate = useNavigate();

  const entityFields = [
    {
      name: "appModule.key",
      label: "App",
      fieldType: "textField",
    },
    {
      name: "client.name",
      label: "Client",
      fieldType: "textField",
    },
    {
      name: "startDate",
      label: "Start Date",
      readOnly: true,
      fieldType: "textField",
      render: (values) =>
        values.startDate
          ? formatDateToRead(values.startDate, globalDateTimeFormat.read)
          : "",
    },
    {
      name: "endDate",
      label: "End Date",
      readOnly: true,
      fieldType: "textField",
      render: (values) =>
        values.endDate
          ? formatDateToRead(values.endDate, globalDateTimeFormat.read)
          : "",
    },
  ];

  const links = [
    {
      name: "Subscription",
      url: "#/" + enums.models.subscriptions,
      isDisplayLink: true,
    },
    {
      name: values.client.name,
      url: "",
      isDisplayLink: false,
    },
  ];

  const actions = [
    {
      label: "Cancel",
      iconName: "cancel",
      hidden: !isEditable,
      onClick: () => handleCancel(),
    },
    {
      label: "Save",
      iconName: "save",
      hidden: !isEditable,
      type: "submit",
      //   onClick: formMethods.handleSubmit(onSubmit),
    },
    {
      label: "Edit",
      iconName: "edit",
      hidden: true, // isEditable
      onClick: () => {
        // setIsEditable(true);
      },
    },
  ];

  const handleCancel = () => {
    if (window.confirm("Are you sure to cancel? Changes will be discarded.")) {
      if (params?.id === "create") {
        navigate("/subscriptions");
      } else {
        formMethods.reset();
        setIsEditable(false);
      }
    }
  };

  return (
    <>
      <Header links={links} pageTitle={"Subscriptions"} actions={actions} />

      <GeneralSection
        fields={entityFields}
        title="Information"
        isEditable={isEditable}
        values={values}
      />
    </>
  );
};

export default DetailHeader;
