import React from "react";
import { globalDateTimeFormat } from "@ui/ReactHookFormControls/index";
import DateFnsUtils from "@date-io/date-fns";
import {
  KeyboardDateTimePicker,
  MuiPickersUtilsProvider,
} from "@material-ui/pickers";

const MyDateTimePicker = ({
  clearable = true,
  autoOk = true,
  format = globalDateTimeFormat.edit,
  defaultValue,
  rules,
  label,
  onChange,
  value,
  disableFuture,
  disablePast,
  helperText,
  error,
  maxDate,
  minDate,
  ...props
}) => {
  return (
    <MuiPickersUtilsProvider utils={DateFnsUtils}>
      <KeyboardDateTimePicker
        {...props}
        label={label}
        clearable={clearable}
        autoOk={autoOk}
        size="small"
        style={props.style || { width: "100%" }}
        inputVariant="outlined"
        format={format || globalDateTimeFormat.edit}
        value={value ? value : null}
        onChange={(newValue) => {
          onChange(newValue);
        }}
        disablePast={disablePast}
        disableFuture={disableFuture}
        maxDate={maxDate || new Date("2100-01-01")}
        minDate={minDate || new Date("1900-01-01")}
        helperText={helperText}
        error={Boolean(error)}
      />
    </MuiPickersUtilsProvider>
  );
};

export default MyDateTimePicker;
