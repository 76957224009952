import React from "react";
import enums from "helpers/enums";
import GeneralListPage from "@ui/ComponentUtils/GeneralListPage";
import { formatDateToRead, joinWithSeparator } from "@ui/Utils/helper";
import { globalDateTimeFormat } from "@ui/ReactHookFormControls/index";

const columns = [
  {
    title: "App",
    field: "appModule.key",
  },

  {
    title: "Client",
    field: "client",
    render: (row) => row.client?.name || "",
  },
  {
    title: "Start Date",
    field: "startDate",
    render: (row) => (row.startDate ? formatDateToRead(row.startDate) : ""),
  },
  {
    title: "End Date",
    field: "endDate",
    render: (row) => (row.endDate ? formatDateToRead(row.endDate) : ""),
  },
  {
    title: "Subscribed By",
    // field: "createdBy.firstName",
    sorting: false, // due to invalid createdBy.firstName old data
    render: (row) => (
      <div>
        <div style={{ fontWeight: "bold" }}>
          {joinWithSeparator(
            [row.createdBy?.firstName, row.createdBy?.lastName],
            " "
          )}
        </div>
        <div style={{ fontSize: "10px", fontStyle: "italic" }}>
          {row.dateCreated
            ? formatDateToRead(row.dateCreated, globalDateTimeFormat.read)
            : ""}
        </div>
      </div>
    ),
  },
  {
    title: "Modified By",
    sorting: false, // due to invalid createdBy.firstName old data
    render: (row) => (
      <div>
        <div style={{ fontWeight: "bold" }}>
          {joinWithSeparator(
            [row.modifiedBy?.firstName, row.modifiedBy?.lastName],
            " "
          )}
        </div>
        <div style={{ fontSize: "10px", fontStyle: "italic" }}>
          {row.dateModified
            ? formatDateToRead(row.dateModified, globalDateTimeFormat.read)
            : ""}
        </div>
      </div>
    ),
  },
  {
    title: "Status",
    field: "status",
    sorting: false, // due to invalid createdBy.firstName old data
    render: (row) => (
      <div style={{ color: row.status ? "green" : "red", fontWeight: "bold" }}>
        {row.status ? "Active" : "Expired"}
      </div>
    ),
  },
];

const SubscriptionsList = () => {
  return (
    <GeneralListPage
      columns={columns}
      model={enums.models.subscriptions}
      headerTitle="Subscriptions"
      hideAction={{ delete: true, selection: true, create: true }}
      userFields={["$createdBy.firstName", "$createdBy.lastName"]}
      searchFields={["appModule.key"]}
      dateFields={["startDate", "endDate", "dateCreated"]}
      mainQuery={{ populate: { path: "client", select: "name" } }}
      withHook={true}
    />
  );
};

export default SubscriptionsList;
