import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
// import Grid from "@mui/material/Grid";
// import CustomEventEmitter from "@ui/Utils/CustomEventEmitter";
// import { downloadFile, isAdmin } from "@ui/Utils/helper";
// import { format } from "date-fns";
import React, { useEffect, useRef, useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { useNavigate, useParams, useLocation } from "react-router-dom";
// import { createGlobalStyle } from "styled-components";
import CustomEventEmitter from "@ui/Utils/CustomEventEmitter";
import {
  createRecord,
  getByCode,
  updateRecord,
} from "@ui/ComponentUtils/blueprintAPIs";

import _ from "lodash";

import enums from "helpers/enums";
// import DetailContent from "./components/DetailContent";
import DetailHeader from "./components/DetailHeader";

const validationSchema = yup.object().shape({});

const entitySchema = { client: { name: "" } };

const SubscriptionDetail = () => {
  const formMethods = useForm({
    defaultValues: entitySchema,
    mode: "all",
    resolver: yupResolver(validationSchema),
  });
  const navigate = useNavigate();

  const params = useParams();

  const [isEditable, setIsEditable] = useState(false);

  useEffect(() => {
    (async () => {
      const code = params?.code;

      try {
        if (code !== "create") {
          const res = await getByCode(
            enums.models.subscriptions,
            code,
            `populate=${JSON.stringify({ path: "client", select: "name" })}`
          );

          formMethods.reset(res);
          setIsEditable(false);
        } else {
          setIsEditable(true);
        }
      } catch (err) {
        navigate("/clients");
        console.log(err);
      }
    })();
  }, [params?.id]);

  //   const onSubmit = async (values, metaData) => {
  //     try {
  //       let res = {},
  //         action = "Created";

  //       if (!values.code) {
  //         res = await createRecord(enums.models.clients, values);
  //         navigate(`/${enums.models.clients}/${res?.code}`);
  //       } else {
  //         res = await updateRecord(enums.models.clients, {
  //           ...values,
  //           populate: "appModules.subscription",
  //         });
  //         navigate(`/${enums.models.clients}/${res?.code}`);
  //         action = "Updated";
  //       }
  //       setIsEditable(false);
  //       formMethods.reset(res);

  //       CustomEventEmitter.emit("alert_success", "Successfully " + action);
  //     } catch (err) {
  //       console.log(err?.response?.data?.message);
  //       let message = err?.response?.data?.message || "Something went wrong!";

  //       CustomEventEmitter.emit("alert_error", message);
  //     }
  //   };

  return (
    <FormProvider {...formMethods}>
      <DetailHeader
        isEditable={isEditable}
        setIsEditable={setIsEditable}
        // onSubmit={onSubmit}
      />

      {/* <DetailContent isEditable={isEditable} /> */}
    </FormProvider>
  );
};

export default SubscriptionDetail;
