const enums = {
  roles: {
    superAdmin: "Super Admin",
    admin: "Admin",
    manager: "Manager",
    branch: "Branch",
    zoneManager: "Zone Manager",
    inventoryManager: "Inventory Manager",
    salesPerson: "Sales Person",
    vendor: "Vendor",
    customer: "Customer",
    regular: "Regular",
  },

  fieldTypes: {
    textField: "textField",
    dropdown: "dropdown",
    featureCheckbox: "featureCheckbox",
    asyncDropdown: "asyncDropdown",
    upload: "upload",
  },
  dataTypes: {
    string: "string",
    number: "number",
    object: "object",
    array: "array",
  },

  models: {
    dashboard: "iam/dashboard",
    clients: "iam/clients",
    users: "iam/users",
    backups: "iam/backups",
    appModules: "iam/appModules",

    countries: "iam/countries",
    states: "iam/states",
    cities: "iam/cities",

    javascripts: "iam/javascripts",
    subscriptions: "iam/subscriptions",

    divisions: "data-engine/divisions",
  },

  businessType: {
    catalogist: "Catalogist",
    stockist: "Stockist",
  },

  appModules: {
    iam: {
      appModule: "iam",
      label: "IAM",
      models: ["users"], // not used anywhere yet..
    },
    "data-engine": {
      appModule: "data-engine",
      label: "Data Engine",
      subModules: ["sheen-op"],
    },
    "e-creatives": {
      appModule: "e-creatives",
      label: "Order PRO",
    },
    "vendor-portal": {
      appModule: "vendor-portal",
      label: "Order Portal",
    },
  },

  stockStatus: {
    staged: "Staged",
    inventory: "In Inventory",
    approval: "In Approval",
    issued: "Issued",
    unAvailable: "Unavailable",
  },
};

export default enums;
