import GeneralSection from "@ui/ComponentUtils/GeneralSection";
import Header from "@ui/ComponentUtils/Header";
// import enums from "helpers/enums";
import React, { useEffect, useState } from "react";
import { useFormContext } from "react-hook-form";
import { useNavigate, useParams } from "react-router-dom";
// import { formatDateToRead } from "@ui/Utils/helper";
// import { globalDateTimeFormat } from "@ui/ReactHookFormControls/index";
import commonEnums from "@ui/Utils/commonEnums";
import { search } from "@ui/ComponentUtils/blueprintAPIs";
import { uniqueArray } from "@ui/Utils/helper";

const DetailHeader = ({ isEditable, onSubmit, setIsEditable }) => {
  const formMethods = useFormContext();
  const values = formMethods.getValues();
  const params = useParams();
  const navigate = useNavigate();
  const [sources, setSources] = useState([]);

  useEffect(() => {
    (async () => {
      try {
        setSources(
          uniqueArray(
            (
              (await search(commonEnums.models["data-engine"].softAllies)) || []
            ).map((e) => e.name)
          )
        );
      } catch (_) {}
    })();
  }, []);

  const entityFields = [
    {
      name: "name",
      label: "Name",
      fieldType: "dropdown",
      options: sources,
      freeSolo: true,
      autoSelect: true,
    },
    {
      name: "logo",
      label: "Logo",
      fieldType: "upload",
      model: commonEnums.models["data-engine"].softAllies,
      additionalPath: "logo",
      accept: "image/*",
      single: true,
      previewThumbnail: true,
      required: true,
    },
  ];

  const links = [
    {
      name: "Allieee",
      url: "#/" + commonEnums.models["data-engine"].softAllies,
      isDisplayLink: true,
    },
    {
      name: values.name,
      url: "",
      isDisplayLink: false,
    },
  ];

  const actions = [
    {
      label: "Cancel",
      iconName: "cancel",
      hidden: !isEditable,
      onClick: () => handleCancel(),
    },
    {
      label: "Save",
      iconName: "save",
      hidden: !isEditable,
      type: "submit",
      onClick: formMethods.handleSubmit(onSubmit),
    },
    {
      label: "Edit",
      iconName: "edit",
      hidden: isEditable,
      onClick: () => {
        setIsEditable(true);
      },
    },
  ];

  const handleCancel = () => {
    if (window.confirm("Are you sure to cancel? Changes will be discarded.")) {
      if (params?.id === "create") {
        navigate(commonEnums.models["data-engine"].softAllies);
      } else {
        formMethods.reset();
        setIsEditable(false);
      }
    }
  };

  return (
    <>
      <Header links={links} pageTitle={"Soft Ally"} actions={actions} />

      <GeneralSection
        fields={entityFields}
        title="Information"
        isEditable={isEditable}
        values={values}
      />
    </>
  );
};

export default DetailHeader;
