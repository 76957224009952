import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
// import Grid from "@mui/material/Grid";
// import CustomEventEmitter from "@ui/Utils/CustomEventEmitter";
// import { downloadFile, isAdmin } from "@ui/Utils/helper";
// import { format } from "date-fns";
import React, { useEffect, useRef, useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { useNavigate, useParams, useLocation } from "react-router-dom";
// import { createGlobalStyle } from "styled-components";
import CustomEventEmitter from "@ui/Utils/CustomEventEmitter";
import {
  createRecord,
  getByCode,
  updateRecord,
} from "@ui/ComponentUtils/blueprintAPIs";

import _ from "lodash";

import enums from "helpers/enums";
import DetailContent from "./components/DetailContent";
import DetailHeader from "./components/DetailHeader";

// const GlobalStyle = createGlobalStyle`
//    body {
//     background: linear-gradient(
//     180deg,
//     rgba(255, 88, 88, 0.1) 0%,
//     rgba(123, 166, 229, 0.31) 69.79%
//   );
//     font-family: 'Advent Pro', sans-serif;
//     min-height: 100vh;
//     padding-inline: 2vh;
//     overflow: auto;
//   }

// `;

const validationSchema = yup.object().shape({
  name: yup
    .string()
    .required("Required")
    .matches(/^(?!.*  ).*$/, "Name cannot contain consecutive spaces"),

  // subDomain: yup.string().required("Required"),
  phone: yup
    .number()
    .required("Required")
    .typeError("Phone number must be a valid number"),
  logo: yup.object().shape({
    location: yup.string().required("Location is required"),
  }),
  businessType: yup.string().nullable().required("Required"),
});

const entitySchema = {};

const ClientDetail = () => {
  const formMethods = useForm({
    defaultValues: entitySchema,
    mode: "all",
    resolver: yupResolver(validationSchema),
  });
  const navigate = useNavigate();

  const params = useParams();

  const [isEditable, setIsEditable] = useState(false);

  useEffect(() => {
    (async () => {
      const code = params?.code;

      try {
        if (code !== "create") {
          const res = await getByCode(
            enums.models.clients,
            code,
            "populate=appModules.subscription"
          );

          formMethods.reset(res);
          setIsEditable(false);
        } else {
          setIsEditable(true);
        }
      } catch (err) {
        navigate("/clients");
        console.log(err);
      }
    })();
  }, [params?.id]);

  const onSubmit = async (values, metaData) => {
    try {
      let res = {},
        action = "Created";
      const divisionLevelConfig =
        values.appConfig?.["data-engine"]?.divisionLevel;

      if (
        divisionLevelConfig &&
        Object.keys(divisionLevelConfig).find(
          (divId) =>
            divisionLevelConfig?.[divId]?.divaIntegration?.apiUrl
              ?.toString()
              .trim() &&
            !divisionLevelConfig?.[divId]?.divaIntegration?.apiUrl?.endsWith(
              "/"
            )
        )
      ) {
        return CustomEventEmitter.emit(
          "alert_error",
          "All Diva API Url should end with forward slash /"
        );
      }

      if (!values.code) {
        res = await createRecord(enums.models.clients, values);
        navigate(`/${enums.models.clients}/${res?.code}`);
      } else {
        res = await updateRecord(enums.models.clients, {
          ...values,
          populate: "appModules.subscription",
        });
        navigate(`/${enums.models.clients}/${res?.code}`);
        action = "Updated";
      }
      setIsEditable(false);
      formMethods.reset(res);

      CustomEventEmitter.emit("alert_success", "Successfully " + action);
    } catch (err) {
      console.log(err?.response?.data?.message);
      let message = err?.response?.data?.message || "Something went wrong!";

      CustomEventEmitter.emit("alert_error", message);
    }
  };

  return (
    <FormProvider {...formMethods}>
      <DetailHeader
        isEditable={isEditable}
        setIsEditable={setIsEditable}
        onSubmit={onSubmit}
      />

      <DetailContent isEditable={isEditable} />
    </FormProvider>
  );
};

export default ClientDetail;
