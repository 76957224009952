import React from "react";

// icons
import DashboardIcon from "@mui/icons-material/Dashboard";
import InsertEmoticonIcon from "@mui/icons-material/InsertEmoticon";
import PeopleIcon from "@mui/icons-material/People";
import JavascriptIcon from "@mui/icons-material/Javascript";
import ProductionQuantityLimitsIcon from "@mui/icons-material/ProductionQuantityLimits";
import SubscriptionsOutlinedIcon from "@mui/icons-material/SubscriptionsOutlined";
import SubscriptionsIcon from "@mui/icons-material/Subscriptions";
// Components
import ClientsList from "views/Clients/List/List";
import ClientDetail from "views/Clients/Detail/Detail";
import UsersList from "views/Users/List/List";
import UserDetail from "views/Users/Detail/Detail";

// utils
import HomePage from "pages/HomePage/HomePage";
import AppModulesList from "views/AppModules/List/List";
import AppModuleDetail from "views/AppModules/Detail/Detail";
import enums from "helpers/enums";
import DashboardList from "views/Dashboard/List/List";
import JavaScriptsList from "views/JavaScripts/List/List";
import ScriptDetail from "views/JavaScripts/Detail/Detail";
import SubscriptionsList from "views/Subscriptions/List/List";
import SubscriptionDetail from "views/Subscriptions/Detail/Detail";
import commonEnums from "@ui/Utils/commonEnums";
import AgricultureIcon from "@mui/icons-material/Agriculture";
import SoftAlleyDetail from "views/SoftAllies/Detail/Detail";
import SoftAlliesList from "views/SoftAllies/List/List";

export const primaryMenuRoutes = [
  {
    access: true,
    title: "Apps",
    path: enums.models.appModules,
    icon: () => <ProductionQuantityLimitsIcon />,
    component: () => <AppModulesList />,
  },
  // {
  //   access: true,
  //   title: "Backups",
  //   path: enums.models.backups,
  //   icon: () => <CategoryIcon />,
  //   component: () => <BackupDetail />,
  // },

  {
    access: true,
    title: "Clients",
    path: enums.models.clients,
    icon: () => <InsertEmoticonIcon />,
    component: () => <ClientsList />,
    hideMenuByDefault: true,
  },
  {
    access: true,
    title: "Dash",
    path: enums.models.dashboard,
    icon: () => <DashboardIcon />,
    component: () => <DashboardList />,
    hideMenuByDefault: true,
  },
  {
    access: true,
    title: "JavaScripts",
    path: enums.models.javascripts,
    icon: () => <JavascriptIcon />,
    component: () => <JavaScriptsList />,
  },
  {
    access: true,
    title: "Soft Allies",
    path: commonEnums.models["data-engine"].softAllies,
    icon: () => <AgricultureIcon />,
    component: () => <SoftAlliesList />,
  },
  {
    access: true,
    title: "Subscriptions",
    path: enums.models.subscriptions,
    icon: () => <SubscriptionsOutlinedIcon />,
    selectedIcon: () => <SubscriptionsIcon />,
    component: () => <SubscriptionsList />,
  },

  {
    access: true,
    title: "Users",
    path: enums.models.users,
    icon: () => <PeopleIcon />,
    component: () => <UsersList />,
    hideMenuByDefault: true,
  },
];

export const secondaryMenuRoutes = [
  {
    access: true,
    title: "Clients",
    path: enums.models.clients + "/:code",
    component: () => <ClientDetail />,
  },
  {
    access: true,
    title: "Users",
    path: enums.models.users + "/:code",
    component: () => <UserDetail />,
  },
  {
    access: true,
    title: "Apps",
    path: enums.models.appModules + "/:code",
    component: () => <AppModuleDetail />,
  },
  {
    access: true,
    title: "JavaScripts",
    path: enums.models.javascripts + "/:code",
    component: () => <ScriptDetail />,
  },
  {
    access: true,
    title: "Subscriptions",
    path: enums.models.subscriptions + "/:code",
    component: () => <SubscriptionDetail />,
  },
  {
    access: true,
    title: "API",
    path: commonEnums.models["data-engine"].softAllies + "/:code",
    component: () => <SoftAlleyDetail />,
  },
];

// //add routes injection below for different app modules
// import appRoutes from "./routes/index";

// const baseRoutes = [
//   // {
//   // 	path: '/',
//   // 	exact: true,
//   // 	component: () => <Redirect to="/dashboards/default" />,
//   // },
// ];

// const combinedRoute = baseRoutes.concat(appRoutes);

// export default combinedRoute;
