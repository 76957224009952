import MatTable from "@ui/components/MatTable";
import MainSection from "@ui/MuiComponents/Section/MainSection";
import { globalDateTimeFormat } from "@ui/ReactHookFormControls/index";
import { formatDateToRead } from "@ui/Utils/helper";
import React, { useRef, useState } from "react";
import { useFormContext } from "react-hook-form";

const DetailContent = ({ values, isEditable }) => {
  const { setValue } = useFormContext();
  const [selectedRows, setSelectedRows] = useState([]);
  const tableRef = useRef();

  // ip: String,
  // userAgent: String,
  // loginAt: Date,
  // sessionId: String,
  const onSelectionChange = (rows) => {
    let currentPageNum = tableRef.current.state.query.page;
    if (selectedRows && selectedRows.length + 1 < currentPageNum) {
      for (let index = selectedRows.length; index < currentPageNum; index++) {
        selectedRows[index] = [];
      }
    }
    selectedRows && selectedRows.splice(currentPageNum, 1, rows);
    setSelectedRows(rows);
  };

  return (
    <MainSection title="Active Sessions">
      <MatTable
        tableRef={tableRef}
        title=""
        options={{ selection: isEditable }}
        onSelectionChange={onSelectionChange}
        data={values.activeSessions || []}
        actions={[
          {
            icon: "power",
            tooltip: "log it out",
            disabled: !selectedRows?.length,
            isFreeAction: false,
            hidden:
              !values.code || !isEditable || !values.activeSessions?.length,
            onClick: async (_) => {
              setValue(
                "activeSessions",
                values.activeSessions.filter(
                  (e) => !selectedRows.some((s) => s.app === e.app)
                )
              );
              // await updateRecord(enums.models.users, {
              //   code: values.code,
              //   activeSessions: values.activeSessions.filter((e) =>
              //     selectedRows.some((s) => s.app === e.app)
              //   ),
              // });
            },
          },
        ]}
        columns={[
          {
            title: "App",
            field: "app",
          },
          {
            title: "User Agent",
            field: "userAgent",
          },
          {
            title: "Location",
            field: "ip",
            render: (session) => <IpLocation session={session} />,
          },
          {
            title: "Login Time",
            field: "loginAt",
            render: (row) =>
              row.loginAt
                ? formatDateToRead(row.loginAt, globalDateTimeFormat.read)
                : "",
          },
        ]}
      />
    </MainSection>
  );
};

const IpLocation = ({ session }) => {
  //   const [location, setLocation] = useState("Loading...");

  //   useEffect(() => {
  //     if (!ip) return;
  //     fetch(`https://ip-api.com/json/${ip}`)
  //       .then((res) => res.json())
  //       .then((data) =>
  //         // "status": "success",
  //         // "country": "India",
  //         // "countryCode": "IN",
  //         // "region": "MH",
  //         // "regionName": "Maharashtra",
  //         // "city": "Mumbai",
  //         // "zip": "400017",
  //         // "lat": 19.0748,
  //         // "lon": 72.8856,
  //         // "timezone": "Asia/Kolkata",
  //         // "isp": "Bharti Airtel Limited",
  //         // "org": "Bharti Airtel Limited",
  //         // "as": "AS24560 Bharti Airtel Ltd., Telemedia Services",
  //         // "query": "2401:4900:8814:4d75:b83f:c05c:b221:98d4"
  //         setLocation(
  //           data.status === "success"
  //             ? `${data.country}, ${data.regionName}, ${data.city}, ${data.zip}, ${data.org}`
  //             : ""
  //         )
  //       )
  //       .catch(() => setLocation("Unknown"));
  //   }, [ip]);

  return (
    <span>
      {session?.geoStats?.country
        ? `${session.geoStats.country}, ${session.geoStats.regionName}, ${session.geoStats.city}, ${session.geoStats.zip}, ${session.geoStats.org}`
        : ""}
    </span>
  );
};

export default DetailContent;
